import './css/Background.css';
import './css/index.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Mengimpor Switch dari react-router-dom
import {Dashboard} from '../src/public/Dashboard.jsx';
import { Login } from './auth/Login.jsx';
import { Register } from './auth/Register.jsx';
import { NULL } from './public/Null.jsx';
import { UsersLoad } from './public/Users.jsx';
import { Logout } from './auth/Logout.jsx';


const App = () => {

  useEffect(() => {
    // Code to create stars and their animation
    const createStar = () => {
      const star1 = document.createElement('div');
      const xy = getRandomPosition();
      star1.classList.add('star1');
      star1.style.left = `${xy[0]}px`;
      star1.style.top = `${xy[1]}px`;
      document.body.appendChild(star1);
      setTimeout(() => {
        star1.remove();
      }, 25000);
    };

    const getRandomPosition = () => {
      const x = Math.random() * window.innerWidth;
      const y = Math.random() * window.innerHeight;
      return [x, y];
    };

    const interval = setInterval(createStar, 500);

    return () => clearInterval(interval);
  }, []); 

  return (
    <>
    <Router>
      <div className='container'>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/null" element={<NULL />} />
          <Route path="/user" element={<UsersLoad />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </div>
    </Router>    
    </>

  );
}


export default App;
