//Register.jsx
import '../css/from.css';
import React, { useState, useEffect } from 'react';
import {Splash} from '../public/Splash.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { WssUrl } from '../conf/Wss.jsx';

export function Register(){
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Simulasikan waktu pemuatan
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Ubah angka ini sesuai kebutuhan
  
      // Hapus timeout jika komponen dibongkar
      return () => clearTimeout();
    }, []);
    return (
      <>
      {loading ? <Splash /> : <MainRegister />}
      </>
    )
}

function MainRegister () {
  const pindah = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nomer, setnomer] = useState("");

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [socket, setSocket] = useState(null);
    
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handlenomer = (event) => {
    setnomer(event.target.value);
  };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const pesanJson = {
            client_register:'arearegister',
            email:email,
            password:password,
            no:nomer
          }
          
          if (socket) {
            socket.send(JSON.stringify(pesanJson));
            // localStorage.setItem('cl', pesan);
          }
        } catch (error) {
          
        }
    };

  useEffect(() => {
    const ws = new WebSocket(WssUrl);
    ws.onopen = () => {
      console.log("Connected to WebSocket Server");
      setSocket(ws);

      const a = {
        "id":"bergup2p"
      }
      ws.send(JSON.stringify(a))
      
      // const i = localStorage.getItem('login');
    };

    ws.onmessage = (event) => {
      const receivedMessage = event.data;          
        try {
          const pesanJson = JSON.parse(receivedMessage);
      
          if (pesanJson){

            const dariServer = pesanJson.server_msg;
            if (dariServer){setSuccessMessage(dariServer);setErrorMessage('');}
            if (dariServer === 'ok'){pindah('/login');}

            const jikaerror = pesanJson.server_msg_error;
            if (jikaerror){setErrorMessage(jikaerror);setSuccessMessage('');}
            
          }
          console.log('pesan Json ', pesanJson);
        } catch (error) {
          
        }
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket Server");
    };

    return () => {
      ws.close();
    };
  }, [pindah]);
    
  return (
    <>
    <div className='container'>
        <div className="form-container">
            <h1>Register</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email:</label>
                <input type="email" value={email} onChange={handleEmailChange} required/>
        
                <label htmlFor="password">Password:</label>
                <input type="password" value={password} onChange={handlePasswordChange} required/>

                <label htmlFor="nomer">Number:</label>
                <input type="text" value={nomer} onChange={handlenomer} required
                placeholder='628xxxxxxx'
                />
        
                <button type="submit" >Register</button>
            </form>

            {successMessage && <div className="success-message">{successMessage}</div>}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div style={{marginTop:"100px"}}>
                <div style={{float:"left"}}>
                    <Link to={"/"}>home</Link>
                </div>
                <div style={{float:"right"}}>
                    <Link to={"/login"}>Login</Link>
                </div>
            </div>            
        </div>

    </div>
    </>

  );
}