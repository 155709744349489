//Navbar.jsx
import { Link , useNavigate } from 'react-router-dom';
import React, { useState , useEffect} from 'react';
import '../css/Navbar.css';

export function Navbar() {
    const pindah = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const cek_dulu = sessionStorage.getItem('tokenID');
        setIsLoggedIn(cek_dulu ? true : false); // Menentukan status login berdasarkan keberadaan token
      }, []);

    const handleLogout = () => {
    sessionStorage.removeItem('tokenID');
    pindah("/login");
    };

    return (
        <div className="">
            <div className="kepala">
                <div className="navbar">
                    <div className="kiri">
                    <Link to="/">Home</Link> 
                    </div>
                    

                    {isLoggedIn ?(
                            <div className="kanan">
                                {/* <Link to="/user">chat</Link> */}
                                <Link to="/null">About</Link>
                                <Link to="/null">Services</Link>
                                <Link to="/logout" className="navbar-link" onClick={handleLogout}>Logout</Link>
                            </div>
                        
                        ):(
                            <div className="kanan">
                                {/* <Link to="/user">chat</Link> */}
                                <Link to="/null">About</Link>
                                <Link to="/null">Services</Link>
                                <Link to="/login">Wallet</Link>
                            </div>
                        )
                    }
                </div>

            </div>            
        </div>

    );
}
