// UsersLoad.jsx
import React, { useState, useEffect } from 'react';
import {Splash} from './Splash';
import '../css/Chat.css'

export function UsersLoad(){
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); 
    return () => clearTimeout();
  }, []);
  return (
    <>
    {loading ? <Splash /> : <Users />}
    </>
  )
}

function Users() {
  const [pesan , setpesan] = useState("");
  const [online , setonline] = useState("");

  const [status , setstatus] = useState('');
  const [socket, setSocket] = useState(null);
  const [server_messages, server_setMessages] = useState([]);
  const [client_messages, client_setMessages] = useState([]);
  //
  const rubah_pesan = (event) => {
    setpesan(event.target.value);
  };

  useEffect(() => {
    const ws = new WebSocket("ws://localhost:8080");
    ws.onopen = () => {
      console.log("Connected to WebSocket Server");
      setstatus('server online')
      setSocket(ws);

      const a = {
        "id":"bergup2p"
      }
      ws.send(JSON.stringify(a))
      
      const i = localStorage.getItem('cl');
      // const i = JSON.parse(localStoragegetItem('cl'));
      if(i)client_setMessages((prevMessages) => [...prevMessages, i]);
    };

    ws.onmessage = (event) => {
      const receivedMessage = event.data;          
        try {
          const pesanJson = JSON.parse(receivedMessage);
      
          if (pesanJson){
            const dariServer = pesanJson.server_msg;
            if (dariServer)server_setMessages((prevMessages) => [...prevMessages, dariServer]);
            const dariClient = pesanJson.client_msg;
            if (dariClient)client_setMessages((prevMessages) => [...prevMessages, dariClient]);
            
            const ON = pesanJson.online;
            if(ON)setonline(ON);
          }
          
          console.log('pesan Json ', pesanJson);
        } catch (error) {
          
        }
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket Server");
      setstatus('server offline')
    };

    return () => {
      ws.close();
    };
  }, []);

  const sendMessage = () => {
    const pesanJson = {
      client_msg:pesan
    }
    
    if (socket) {
      socket.send(JSON.stringify(pesanJson));
      setpesan("");
      localStorage.setItem('cl', pesan);
      // localStorage.setItem('cl', JSON.stringify(pesanJson));
    }

  };

  return (
    <>
        {/* info on off */}
        <div className='chat_box'>
            <div className='kurung_notif_online'>
                <div className='lamp_aktif'>
                </div>
                <span>{status}</span>
            </div>
            <div className='kurung_notif_offline'>
                <div className='lamp_offline'>
                </div>
                <span>user on {online}</span>
            </div>
        </div>

        {/* chat */}
        <div className='chat_box_ui'>
          {server_messages.map((message, index) => (
            <div key={index} className="message">
              <span className='Server'>
                <span className='admin'>Server:</span>{message}
                <span className='time'>{getWaktuSekarang()}</span>
              </span>
            </div>
          ))}

          {client_messages.map((message, index) => (
            <div key={index} className="message">
              <span className='Client'>
                <span className='admin'>Client:</span>{message}
                <span className='time'>{getWaktuSekarang()}</span>
              </span>
            </div>
          ))}

      </div>

        {/* input dan tombol */}
        <div className='chat_box'>
            <input
            style={{
                borderRadius:'10px'
                
            }}
            type="text" name="" id="" 
            value={pesan} onChange={rubah_pesan}
            />

            <span
            onClick={sendMessage}
            style={{
                marginLeft:'10px',padding:'5px',background:'black',
                borderRadius:'10px',cursor:'pointer'
            }}
            >SEND</span>
        </div>
    </>    
    
  );
}

function getWaktuSekarang() {
  // Dapatkan tanggal dan waktu saat ini
  var sekarang = new Date();

  // Dapatkan jam dan menit
  var jam = sekarang.getHours();
  var menit = sekarang.getMinutes();

  // Format jam dan menit menjadi format hh:mm
  jam = jam < 10 ? '0' + jam : jam;
  menit = menit < 10 ? '0' + menit : menit;

  // Gabungkan jam dan menit menjadi format hh:mm
  var waktuSekarang = jam + ':' + menit;

  return waktuSekarang;
}