//logout.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function Logout(){
  //redirect
  const pindah = useNavigate();
  useEffect(() => {
    const cek_dulu = sessionStorage.getItem('tokenID');
    if (cek_dulu){
        pindah("/");
    }
    if (!cek_dulu){
        pindah("/login");
    }
  }, [pindah]);
  //

    return(
        <div>
            <p>msg</p>
        </div>
    )
}