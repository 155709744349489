// NULL.jsx
import React, { useState, useEffect } from 'react';
import {Splash} from './Splash';

export function NULL(){
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); 
    return () => clearTimeout();
  }, []);
  return (
    <>
    {loading ? <Splash /> : <EmpatKosongEmpat />}
    </>
  )
}

function EmpatKosongEmpat() {

  return (
    <>
        <h1
        style={{
                fontSize:"100px",position:"fixed",top:"0",
                marginTop:"200px",
                marginLeft:"45%",
              }}>404
              <h1
                  style={{
                    fontSize:"50px",position:"fixed",top:"0",
                    marginTop:"150px",
                    marginLeft:"55px",
                  }}>sorry
              </h1>              
        </h1>

    </>
  );
}
