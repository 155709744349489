// Splash.js
import React from 'react';
import '../css/Splash.css';

export function Splash  () {
  return (
    <div className="splash">
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  );
}


