// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import {Splash} from './Splash';
import { Navbar } from '../com/Navbar';
import BRG from '../img/brg.png'
import SVG from '../img/save.png'

export function Dashboard(){
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulasikan waktu pemuatan
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Ubah angka ini sesuai kebutuhan

    // Hapus timeout jika komponen dibongkar
    return () => clearTimeout();
  }, []);
  return (
    <>
    {loading ? <Splash /> : <MainApp />}
    </>
  )
}

export function MainApp() {
  const [judul] = useState('BERGU');
  return (
    <>
      <div className="box">
        <Navbar />
        <div>
          <h1 className="left">
            <span style={{color:"yellow",fontSize:'50px'}}
            >{judul}
            </span><br />
            <span>Faster Payment Anywhere Everywhere</span>
          </h1>
          <h1 className="img_left">
            <img src={BRG} alt="bergu" className='BRG'/>
          </h1>
          
          <h1 className="left">
          <img src={SVG} alt="bergu" className='BRG'/>
          </h1> 
          {/* <h1 className="right">
            <span style={{color:"yellow",fontSize:'50px'}}
            >{judul}
            </span><br />
            <span>Save Your Money</span>
          </h1> */}
      
        </div>

      </div>
    </>
  );
}
