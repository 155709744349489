//Login.jsx
import React, { useState, useEffect } from 'react';
import {Splash} from '../public/Splash.jsx';
import { Link , useNavigate } from 'react-router-dom';
import { WssUrl } from '../conf/Wss.jsx';

export function Login(){
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Simulasikan waktu pemuatan
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Ubah angka ini sesuai kebutuhan
  
      // Hapus timeout jika komponen dibongkar
      return () => clearTimeout();
    }, []);
    return (
      <>
      {loading ? <Splash /> : <MainLogin />}
      </>
    )
}

function MainLogin () {
  //redirect
  const pindah = useNavigate();
  useEffect(() => {
    const cek_dulu = sessionStorage.getItem('tokenID');
    if (cek_dulu){
        pindah("/");
    }
  }, [pindah]);
  //
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [socket, setSocket] = useState(null);
    
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
    
  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const pesanJson = {
          client_login:'arealogin',
          email:email,
          password:password
        }
        
        if (socket) {
          socket.send(JSON.stringify(pesanJson));
          // localStorage.setItem('cl', pesan);
        }
      } catch (error) {
        
      }
  };

  useEffect(() => {
    const ws = new WebSocket(WssUrl);
    ws.onopen = () => {
      console.log("Connected to WebSocket Server");
      setSocket(ws);

      const a = {
        "id":"bergup2p"
      }
      ws.send(JSON.stringify(a))
      
      // const i = localStorage.getItem('login');
    };

    ws.onmessage = (event) => {
      const receivedMessage = event.data;          
        try {
          const pesanJson = JSON.parse(receivedMessage);
      
          if (pesanJson){

            const dariServer = pesanJson.server_msg;
            if (dariServer){setSuccessMessage(dariServer);setErrorMessage('');};
            if (dariServer === 'ok'){pindah('/')};

            const jikaerror = pesanJson.server_msg_error;
            if (jikaerror){setErrorMessage(jikaerror);setSuccessMessage('');};

            const tokenID = pesanJson.tokenID;
            if (tokenID){
                sessionStorage.setItem('tokenID', tokenID)
            }

          }
          //dev
          console.log('pesan Json ', pesanJson);
        } catch (error) {
          
        }
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket Server");
    };

    return () => {
     ws.close();
    };
  }, [pindah]);
    
      return (
        <>
        <div className='container'>
            <div className="form-container">
                <h1>Login</h1>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Email:</label>
                    <input type="text" value={email} onChange={handleEmailChange} required/>
            
                    <label htmlFor="email">Password:</label>
                    <input type="password" value={password} onChange={handlePasswordChange} />
            
                    <button type="submit" >Login</button>
                </form>

                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <div style={{marginTop:"100px"}}>
                    <div style={{float:"left"}}>
                        <Link to={"/"}>home</Link>
                    </div>
                    <div style={{float:"right"}}>
                        <Link to={"/register"}>Register</Link>
                    </div>
                </div>            
            </div>

        </div>
        </>

      );
}